var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-fullwidth"},[(_vm.salesForceRecords && _vm.salesForceRecords.length > 0)?_c('div',[_c('table',{staticClass:"table is-striped is-fullwidth"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"right","font-weight":"400!important"},attrs:{"colspan":"4"}},[_c('strong',[_vm._v(_vm._s(_vm.getAverageDuration()))]),_vm._v(" avg duration"),_c('br'),_vm._m(0)])]),_vm._m(1)]),_c('tbody',_vm._l((_vm.sort(_vm.salesForceRecords, 'desc', 'runId')),function(record){return _c('tr',{key:record.runId,class:{ 'fade': _vm.isUnjamming && record.status === 'Loading' }},[_c('td',{staticClass:"is-monospaced"},[_vm._v(_vm._s(record.process_name)+" #"+_vm._s(_vm.formatRunId(record.runId)))]),_c('td',{staticClass:"is-monospaced"},[(record.hadError)?_c('v-popover',[_vm._v(" "+_vm._s(record.status)+" "+_vm._s(record.hadError ? '(with error)' : '')+" "),_c('template',{attrs:{"id":"popover"},slot:"popover"},[_vm._v(" "+_vm._s(record.error)+" ")])],2):_c('span',{class:{
              'has-text-danger': record.status === 'Failed',
              'text-warning': record.status === 'Loading',
              'text-grey': record.status === 'Starting Load',
              'text-info': record.status === 'Load Complete',
              'semi-bold': record.status === 'Loading',
              'font-italic': record.status === 'Loading',
              'magnify': record.status === 'Loading',
            }},[_vm._v(_vm._s(record.status))])],1),_c('td',[_c('span',{staticClass:"is-monospaced"},[_vm._v(_vm._s(_vm.moment(record.runDate)))]),(_vm.getDurationRaw(record.runDate, record.updatedDate) > 15 && (record.status === 'Loading' || record.status === 'Pending Load'))?_c('span',[_c('a',{staticStyle:{"margin-left":"5px"},attrs:{"href":"javascript:void()","alt":"Try to unjam the process"},on:{"click":_vm.unjamProcess}},[(!_vm.isUnjamming)?_c('i',{class:['fas fa-redo'],attrs:{"alt":"refresh-icon"}}):_c('i',{staticClass:"fas fa-spinner fa-spin fa-pulse"})])]):_vm._e()]),_c('td',[_c('span',{staticClass:"is-monospaced"},[_vm._v(_vm._s(_vm.getDuration(record.runDate, record.updatedDate)))])])])}),0)])]):_c('LoadingVue')],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('small',[_vm._v("last 30 days")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{staticStyle:{"width":"35%"}},[_vm._v("Import Job")]),_c('th',{staticStyle:{"width":"25%"}},[_vm._v("Status")]),_c('th',{staticStyle:{"width":"30%"}},[_vm._v("Start Time")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Duration")])])}]

export { render, staticRenderFns }